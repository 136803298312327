<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <div class="p-col-12 p-md-6">
                    <h5>{{ $t('menu.editRegion') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.active') }}</label>
                        <div class="p-col-12 p-md-4">
                            <InputSwitch v-model="region.active" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="region.name" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('general.description') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="region.description" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <div class="p-col-12">
                            <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6">
                    <h5>{{ $t('system.contactPerson') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="region.contacts[0].name" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.email') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="region.contacts[0].email" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.telephone') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="region.contacts[0].telephone" />
                        </div>
                    </div>
                </div>
                <transition-group name="p-message" tag="div" class="p-col-12 p-mb-2">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <Fieldset :legend="$t('system.regionShops')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2" v-if="this.isEdit()">
                    <DataTable :value="shops" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll">
                        <Column
                            ><template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                        <Column field="code" :header="$t('system.code')" :sortable="true"></Column>
                        <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                            <template #body="{ data }">
                                <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                            </template>
                        </Column>
                    </DataTable>
                </Fieldset>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'regions',
            errorKeyCount: 0,
            region: {
                active: false,
                contacts: [{}],
            },
            shops: [],
        };
    },
    mounted() {
        if (this.isEdit()) {
            this.BoApi.getRegionDetails(this.$route.params.id)
                .then((response) => {
                    this.region = response.data;
                    this.BoApi.getRegionShops(this.$route.params.id).then((shopsResponse) => {
                        this.shops = shopsResponse.data;
                        this.tableKey++;
                    });
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.errorText = error;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        }
    },
    methods: {
        submitForm: function () {
            if (this.notNullOrEmptyField(this.region.name)) {
                this.disableSubmit = true;
                this.BoApi.saveRegion(this.region)
                    .then(() => {
                        this.goto(this.backRoute);
                    })
                    .catch((error) => {
                        this.checkForUnauthorizedError(error);
                    });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
    },
};
</script>
